export const CURRENT_CONFIG = {

  // license
  appId: '126730', // You need to go to the development website to apply.
  appKey: 'd913b4c6cc8868205026740656a4a13', // You need to go to the development website to apply.
  appLicense: 'BcGgF5TrDHg8/JfoiTB4ENdXnI7htP8WcCkDhpBVz1NooVAnPhInYtPxz72d07JZrnZ06FhEtIRN0ctOaif4aAiAkafpOU2g6r8fpCw1RjXN5Y3ISkqNuEYiGlXl1CVPJXpWiVhxFBOiSGD2R7o62rlLKv4zKqd3uJ03isOdb34=', // You need to go to the development website to apply.

  // http
  baseURL: 'https://dfvision.ddns.net/dji/', // This url must end with "/". Example: 'http://192.168.1.1:6789/'
  websocketURL: 'wss://dfvision.ddns.net/api/v1/ws', // Example: 'ws://192.168.1.1:6789/api/v1/ws'
  mqttURl: 'tcp://dfvision.ddns.net:1883',
  // baseURL: 'https://192.168.0.30:6789/', // This url must end with "/". Example: 'http://192.168.1.1:6789/'
  // websocketURL: 'ws://192.168.0.30:6789/api/v1/ws', // Example: 'ws://192.168.1.1:6789/api/v1/ws'

  // livestreaming
  // RTMP  Note: This IP is the address of the streaming server. If you want to see livestream on web page, you need to convert the RTMP stream to WebRTC stream.
  rtmpURL: 'rtmp://dfvision.ddns.net:8322/dji/', // Example: 'rtmp://192.168.1.1/live/'
  // GB28181 Note:If you don't know what these parameters mean, you can go to Pilot2 and select the GB28181 page in the cloud platform. Where the parameters same as these parameters.
  gbServerIp: 'Please enter the server ip.',
  gbServerPort: 'Please enter the server port.',
  gbServerId: 'Please enter the server id.',
  gbAgentId: 'Please enter the agent id',
  gbPassword: 'Please enter the agent password',
  gbAgentPort: 'Please enter the local port.',
  gbAgentChannel: 'Please enter the channel.',
  // RTSP
  rtspUserName: 'Please enter the username.',
  rtspPassword: 'Please enter the password.',
  rtspPort: '8554',
  // Agora
  agoraAPPID: 'Please enter the agora app id.',
  agoraToken: 'Please enter the agora temporary token.',
  agoraChannel: 'Please enter the agora channel.',

  // map
  // You can apply on the AMap website.
  amapKey: 'Please enter the amap key.',

}
